import { useContext } from "react";
import { CartasContext } from "../../context/CartasContext";
import Carta from "../Carta/Carta";
import { Loading } from "../Loading/Loading";
import { Container, Form } from "react-bootstrap";
import { useGlobalDivMeasures } from "../../hooks/useGlobalDivMeasures";

const Tirada3 = () => {
  const cartasContext = useContext(CartasContext);
  const { globalDivMeasures, updateSize } = useGlobalDivMeasures();

  if (!cartasContext.cartasSeleccionadas) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Form.Range onChange={updateSize} defaultValue={50} />
      </Container>
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: globalDivMeasures.width,
          height: globalDivMeasures.height,
        }}
      >
        {cartasContext.cartasSeleccionadas.map((carta, index) => {
          let rightPosition = 0;
          let topPosition = null;
          if (index < 3) {
            rightPosition =
              0.5 * globalDivMeasures.width +
              0.16 * globalDivMeasures.width * index;
          } else if (index > 2 && index < 6) {
            rightPosition =
              -0.14 * globalDivMeasures.width +
              0.16 * globalDivMeasures.width * index;
            topPosition = globalDivMeasures.height * 0.7;
          } else {
            rightPosition =
              -0.78 * globalDivMeasures.width +
              0.16 * globalDivMeasures.width * index;
            topPosition = globalDivMeasures.height * 1.4;
          }

          return (
            <Carta
              key={carta.id}
              height={`${globalDivMeasures.widthCard}px`}
              width={`${globalDivMeasures.heightCard}px`}
              srcImage={carta.url}
              rightPosition={`${rightPosition}px`}
              topPosition={`${topPosition}px`}
              orientation={carta.orientation}
            />
          );
        })}
      </div>
    </>
  );
};

export default Tirada3;
