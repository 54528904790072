import { useContext, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Select from "react-select";
import { CartaInterface } from "../../interfaces/CartaInterface";
import { CartasContext } from "../../context/CartasContext";
import { Loading } from "../../components/Loading/Loading";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import SeleccionarTarotistaModal from "../../components/SeleccionarTarotistaModal/SeleccionarTarotistaModal";

const Mesa = () => {
  const cartasContext = useContext(CartasContext);
  const [displayAsNegative, setDisplayAsNegative] = useState<boolean>(false);
  const [seleccionarTarotista, setSeleccionarTarotista] =
    useState<boolean>(false);

  const [show, setShow] = useState<boolean>(true);

  const asignarAzar = (): void => {
    let cartasCount = cartasContext!.cartas!.length;
    let cartasSeleccionadasCiclo: CartaInterface[] = [];
    while (
      cartasSeleccionadasCiclo.length <
      cartasContext.tiradaActual.cantidadCartas!
    ) {
      let cartaID = Math.floor(Math.random() * cartasCount);
      if (
        !cartasSeleccionadasCiclo.find(
          (element) => element.id === cartasContext!.cartas![cartaID].id
        )
      ) {
        cartasSeleccionadasCiclo.push({
          ...cartasContext!.cartas![cartaID],
          orientation: !!Math.round(Math.random() * 1),
        });
      }
    }
    cartasContext.alterCartasSeleccionadas(cartasSeleccionadasCiclo);
  };

  const limpiar = (): void => {
    cartasContext.alterCartasSeleccionadas([]);
  };

  const checkDuplicate = (
    cartasMostradas: CartaInterface[],
    cartaAAgregar: CartaInterface
  ): boolean => {
    return !!cartasMostradas.find((element) => element.id === cartaAAgregar.id);
  };

  const addOneCard = (carta: any): void => {
    let cartaSeleccionada = cartasContext!.cartas!.find(
      (element) => element.id === carta.value
    );
    if (
      cartaSeleccionada &&
      cartasContext.cartasSeleccionadas.length <
        cartasContext.tiradaActual.cantidadCartas!
    ) {
      if (
        checkDuplicate(cartasContext.cartasSeleccionadas, cartaSeleccionada)
      ) {
        toast.error("Carta duplicada", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        cartasContext.alterCartasSeleccionadas([
          ...cartasContext.cartasSeleccionadas,
          { ...cartaSeleccionada, orientation: displayAsNegative },
        ]);
      }
    } else {
      toast.error(
        `Ya se tienen ${cartasContext.tiradaActual.cantidadCartas} cartas`,
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const changeShowDirection = (): void => {
    setShow((prev) => !prev);
  };

  if (!cartasContext.cartas) {
    return <Loading />;
  }

  return (
    <>
      <Container
        style={{
          marginTop: "80px",
          display:
            cartasContext.tiradaActual.nombre === "Inicio" ? "none" : "block",
        }}
      >
        <Alert
          style={{
            color: "#ffffff",
            backgroundColor: "rgba(17, 24, 39, 0.7)",
          }}
        >
          <button
            type="button"
            className="btn btn-close "
            aria-label="Close alert"
            onClick={changeShowDirection}
            style={{
              background: "transparent",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 2,
              padding: "1.25rem 1rem",
              boxSizing: "content-box",
              width: "1em",
              height: "1em",
              color: "white",
              border: 0,
              borderRadius: "0.375rem",
              opacity: 0.5,
            }}
          >
            <FontAwesomeIcon
              icon={show ? faChevronUp : faChevronDown}
              style={{ marginRight: 5 }}
            />
          </button>
          <Alert.Heading
            style={{ cursor: "pointer" }}
            onClick={changeShowDirection}
          >
            Tirada {cartasContext.tiradaActual.nombre}
          </Alert.Heading>
          <div className={`accordion-collapse collapse ${show && "show"}`}>
            <Row>
              <Col xs={1} sm={1} md={2} lg={2} xl={2}>
                <Form.Check
                  style={{ color: "white", textAlign: "left" }}
                  type="switch"
                  id="custom-switch"
                  label={
                    <p>
                      <FontAwesomeIcon
                        icon={faRotate}
                        style={{ marginRight: 5 }}
                      />{" "}
                      Negativo
                    </p>
                  }
                  onChange={(event) =>
                    setDisplayAsNegative(event.target.checked)
                  }
                />
              </Col>
              <Col>
                <Select
                  styles={{
                    input: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    option: (baseStyles, { isFocused, isSelected }) => ({
                      ...baseStyles,
                      backgroundColor: isFocused
                        ? "#656467"
                        : isSelected
                        ? "#000000"
                        : "rgb(17, 24, 39)",
                      color: "white",
                    }),
                  }}
                  placeholder="Buscar una carta ..."
                  options={cartasContext.cartas.map((carta: CartaInterface) => {
                    return {
                      value: carta.id,
                      label: carta.nombre,
                    };
                  })}
                  onChange={(carta: any) => addOneCard(carta)}
                />
              </Col>
            </Row>
            <hr />
            <p className="mb-0">
              <Button variant="outline-light" onClick={asignarAzar}>
                Asignar al azar
              </Button>
              <Button variant="outline-light" onClick={limpiar}>
                Limpiar
              </Button>
              <Button
                variant="outline-light"
                onClick={() => setSeleccionarTarotista(true)}
                disabled={
                  cartasContext.cartasSeleccionadas.length !==
                  cartasContext.tiradaActual.cantidadCartas
                }
              >
                Enviar a analizar
              </Button>
            </p>
          </div>
        </Alert>
        <SeleccionarTarotistaModal
          showModal={seleccionarTarotista}
          handleCloseModal={() => setSeleccionarTarotista(false)}
        />
      </Container>
      <Outlet />
    </>
  );
};

export default Mesa;
