//estilos
const classes = require("./Loading.module.css").default;

export const Loading = () => {
  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loader5}>
        <span className={classes.loader5Span} />
      </div>
    </div>
  );
};
