//librerías
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faDice,
  faListCheck,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

//estilos
const classes = require("./Main.module.css").default;

/**
 * Screen Main
 * @description: Es la pantalla que contiene el nav de la aplicación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

const Main = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {/**Navbar */}
      <Navbar
        variant="light"
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 2,
          backgroundColor: "#111827",
          color: "white",
        }}
      >
        <Container>
          <Navbar.Brand style={{ color: "white", fontWeight: "bold" }}>
            Tarot
          </Navbar.Brand>
          <Nav className="me-auto">
            <Link to="mesa/tiradas" className={classes.navlink}>
              <FontAwesomeIcon icon={faDice} style={{ marginRight: 5 }} />
              Tiradas
            </Link>
            {authContext.tokenCan("Tiradas:jugar") && (
              <Link to="sala" className={classes.navlink}>
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  style={{ marginRight: 5 }}
                />
                Mi historial
              </Link>
            )}
            {authContext.tokenCan("Tiradas:analizar") && (
              <Link to="sala" className={classes.navlink}>
                <FontAwesomeIcon
                  icon={faListCheck}
                  style={{ marginRight: 5 }}
                />
                Mis pendientes
              </Link>
            )}
            {authContext.tokenCan("Usuario:store") && (
              <NavDropdown
                style={{ marginRight: "20px", color: "white" }}
                title="Administración"
                className={classes.dropdownContainer}
                menuVariant="dark"
              >
                <Link
                  className={classes.dropdownItem}
                  to="servicios/capturados"
                >
                  Usuarios
                </Link>
                <Link
                  className={classes.dropdownItem}
                  to="servicios/programados"
                >
                  Roles
                </Link>
                <Link
                  className={classes.dropdownItem}
                  to="servicios/por_firmar"
                >
                  Permisos
                </Link>
              </NavDropdown>
            )}
          </Nav>
          <ButtonGroup className="mb-2">
            <Button
              variant="outline-light"
              style={{ float: "right" }}
              onClick={authContext.logout}
              title="Cerrar sesión"
            >
              <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar sesión
            </Button>
          </ButtonGroup>
        </Container>
      </Navbar>

      <div style={{ marginTop: "60px" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Main;
