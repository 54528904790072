import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components/Loading/Loading";
import { ColeccionInterface } from "../../interfaces/ColeccionInterface";
import Tirada1Vis from "../../components/Visualizaciones/Tirada1Vis";
import Tirada2Vis from "../../components/Visualizaciones/Tirada2Vis";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import Tirada3Vis from "../../components/Visualizaciones/Tirada3Vis";
import { AuthContext } from "../../context/AuthContext";
import Tirada5Vis from "../../components/Visualizaciones/Tirada5Vis";
import Tirada4Vis from "../../components/Visualizaciones/Tirada4Vis";
import Tirada6Vis from "../../components/Visualizaciones/Tirada6Vis";
import Tirada7Vis from "../../components/Visualizaciones/Tirada7Vis";
import Tirada8Vis from "../../components/Visualizaciones/Tirada8Vis";
import Tirada9Vis from "../../components/Visualizaciones/Tirada9Vis";
import Tirada10Vis from "../../components/Visualizaciones/Tirada10Vis";
import Tirada11Vis from "../../components/Visualizaciones/Tirada11Vis";
import Tirada12Vis from "../../components/Visualizaciones/Tirada12Vis";
import Tirada13Vis from "../../components/Visualizaciones/Tirada13Vis";
import Tirada14Vis from "../../components/Visualizaciones/Tirada14Vis";
import Tirada15Vis from "../../components/Visualizaciones/Tirada15Vis";
import Tirada16Vis from "../../components/Visualizaciones/Tirada16Vis";
import Tirada17Vis from "../../components/Visualizaciones/Tirada17Vis";

const TiradaDetalle = () => {
  const { idColeccion } = useParams();
  const [coleccion, setColeccion] = useState<ColeccionInterface>();
  const [analisis, setAnalisis] = useState<string>();
  const { sendRequest } = useFetch();
  const authContext = useContext(AuthContext);

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setColeccion(variable.data);
    setAnalisis(variable.data.interpretacion);
  }, []);

  //Función que llama todos los prueba paginados
  useEffect(() => {
    sendRequest(
      {
        url: `/coleccion/${idColeccion}`,
      },
      transformData
    );
  }, [sendRequest, transformData, idColeccion]);

  //Función que desactiva a un usuario.
  const saveAnalisis = () => {
    if (window.confirm("¿Seguro que desea enviar este analisis al usuario?")) {
      sendRequest(
        {
          url: `/coleccion/${idColeccion}`,
          method: "PATCH",
          body: { interpretacion: analisis },
        },
        (variable: any) => {
          toast.success("Se ha registrado el analisis de la tirada", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
    }
  };

  if (!coleccion) {
    return <Loading />;
  }

  return (
    <>
      <h2 style={{ color: "white", fontWeight: "bold" }}>
        {coleccion.tipo_tirada?.nombre}
      </h2>
      <Container>
        <InputGroup>
          <InputGroup.Text>Análisis</InputGroup.Text>
          <Form.Control
            as="textarea"
            aria-label="With textarea"
            onChange={(event) => setAnalisis(event.target.value)}
            value={analisis ? analisis : ""}
            disabled={!authContext.tokenCan("Tiradas:analizar")}
          />
          {authContext.tokenCan("Tiradas:analizar") && (
            <Button variant="outline-light" onClick={saveAnalisis}>
              Enviar
            </Button>
          )}
        </InputGroup>
      </Container>
      {coleccion.tipo_tirada?.id === 1 && (
        <Tirada1Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 2 && (
        <Tirada2Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 3 && (
        <Tirada3Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 4 && (
        <Tirada4Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 5 && (
        <Tirada5Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 6 && (
        <Tirada6Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 7 && (
        <Tirada7Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 8 && (
        <Tirada8Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 9 && (
        <Tirada9Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 10 && (
        <Tirada10Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 11 && (
        <Tirada11Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 12 && (
        <Tirada12Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 13 && (
        <Tirada13Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 14 && (
        <Tirada14Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 15 && (
        <Tirada15Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 16 && (
        <Tirada16Vis cartas={coleccion.cartas} />
      )}
      {coleccion.tipo_tirada?.id === 17 && (
        <Tirada17Vis cartas={coleccion.cartas} />
      )}
    </>
  );
};

export default TiradaDetalle;
