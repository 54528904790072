import React, { useCallback, useEffect, useState } from "react";
import { CartaInterface } from "../interfaces/CartaInterface";
import useFetch from "../hooks/useFetch";
import { useLocation } from "react-router-dom";

interface TiradaActual {
  id: number | null;
  nombre: string | null;
  cantidadCartas: number | null;
}

type CartasContextProps = {
  cartas: CartaInterface[] | undefined;
  cartasSeleccionadas: CartaInterface[];
  alterCartasSeleccionadas: (seleccion: CartaInterface[]) => void;
  tiradaActual: TiradaActual;
};

export const CartasContext = React.createContext({} as CartasContextProps);

export const CartasContextProvider = ({ children }: any) => {
  const { sendRequest } = useFetch();
  const [cartas, setCartas] = useState<CartaInterface[]>();
  const [cartasSeleccionadas, setCartasSeleccionadas] = useState<
    CartaInterface[]
  >([]);
  const [tiradaActual, setTiradaActual] = useState<TiradaActual>({
    id: null,
    nombre: null,
    cantidadCartas: null,
  });

  const location = useLocation();

  const transformData = useCallback((variable: any) => {
    setCartas(variable);
  }, []);

  useEffect(() => {
    setCartasSeleccionadas([]);
    switch (location.pathname) {
      case "/main/mesa/tiradas":
        setTiradaActual({ id: 0, nombre: "Inicio", cantidadCartas: 0 });
        break;
      case "/main/mesa/tirada1":
        setTiradaActual({ id: 1, nombre: "Si o No", cantidadCartas: 5 });
        break;
      case "/main/mesa/tirada2":
        setTiradaActual({
          id: 2,
          nombre: "respuesta contundente",
          cantidadCartas: 6,
        });
        break;
      case "/main/mesa/tirada3":
        setTiradaActual({ id: 3, nombre: "cruzada", cantidadCartas: 9 });
        break;
      case "/main/mesa/tirada4":
        setTiradaActual({
          id: 4,
          nombre: "arcanos mayores",
          cantidadCartas: 22,
        });
        break;
      case "/main/mesa/tirada5":
        setTiradaActual({ id: 5, nombre: "cruz mística", cantidadCartas: 5 });
        break;
      case "/main/mesa/tirada6":
        setTiradaActual({
          id: 6,
          nombre: "Abanico gitano",
          cantidadCartas: 16,
        });
        break;
      case "/main/mesa/tirada7":
        setTiradaActual({
          id: 7,
          nombre: "Perspectiva de negocio",
          cantidadCartas: 8,
        });
        break;
      case "/main/mesa/tirada8":
        setTiradaActual({
          id: 8,
          nombre: "Anual",
          cantidadCartas: 17,
        });
        break;
      case "/main/mesa/tirada9":
        setTiradaActual({
          id: 9,
          nombre: "Vidas pasadas",
          cantidadCartas: 27,
        });
        break;
      case "/main/mesa/tirada10":
        setTiradaActual({
          id: 10,
          nombre: "Mensaje seres queridos",
          cantidadCartas: 21,
        });
        break;
      case "/main/mesa/tirada11":
        setTiradaActual({
          id: 11,
          nombre: "Oráculo del emprendedor",
          cantidadCartas: 7,
        });
        break;
      case "/main/mesa/tirada12":
        setTiradaActual({
          id: 12,
          nombre: "Corona para el trabajo",
          cantidadCartas: 8,
        });
        break;
      case "/main/mesa/tirada13":
        setTiradaActual({
          id: 13,
          nombre: "Desbloqueo de don",
          cantidadCartas: 12,
        });
        break;
      case "/main/mesa/tirada14":
        setTiradaActual({
          id: 14,
          nombre: "Compatibilidad de pareja",
          cantidadCartas: 10,
        });
        break;
      case "/main/mesa/tirada15":
        setTiradaActual({
          id: 15,
          nombre: "Que siente por mi",
          cantidadCartas: 6,
        });
        break;
      case "/main/mesa/tirada16":
        setTiradaActual({
          id: 16,
          nombre: "del sexo",
          cantidadCartas: 6,
        });
        break;
      case "/main/mesa/tirada17":
        setTiradaActual({
          id: 17,
          nombre: "Cruz celta",
          cantidadCartas: 10,
        });
        break;
      default:
        setTiradaActual({ id: null, nombre: null, cantidadCartas: null });
    }
  }, [location.pathname]);

  //Función que llama todos los prueba paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/carta",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const alterCartasSeleccionadas = (seleccion: CartaInterface[]) => {
    setCartasSeleccionadas(seleccion);
  };

  const contextValue = {
    cartas,
    cartasSeleccionadas,
    alterCartasSeleccionadas,
    tiradaActual,
  };

  return (
    <CartasContext.Provider value={contextValue}>
      {children}
    </CartasContext.Provider>
  );
};
