import { useState } from "react";

//estilos
const classes = require("./Carta.module.css").default;

interface Props {
  srcImage: string;
  height?: string;
  width?: string;
  rightPosition: string;
  topPosition?: string;
  orientation?: boolean;
  rotation?: number;
}

const Carta = ({
  srcImage,
  height = "450px",
  width = "240px",
  rightPosition,
  topPosition,
  orientation,
  rotation,
}: Props) => {
  const [showCardContent, setShowCardContent] = useState(false);

  return (
    <img
      alt=""
      className={`${
        orientation ? classes.initialRotated : classes.initialNonRotated
      }`}
      onClick={() => setShowCardContent((prev) => !prev)}
      src={
        showCardContent
          ? require(`../../img/cartas/${srcImage}`)
          : require("../../img/cartas/card_back.jpg")
      }
      style={{
        height,
        width,
        position: "absolute",
        cursor: "pointer",
        top: topPosition,
        right: rightPosition,
        transform:
          orientation && typeof rotation !== "undefined"
            ? `rotate(${rotation + 180}deg)`
            : `rotate(${rotation}deg)`,
      }}
    />
  );
};

export default Carta;
