//librerías
import { Routes, Route, Navigate } from "react-router-dom";

//componentes
import Login from "../Login/Login";
import Main from "../Main/Main";
import Home from "../Home/Home";
import ResetPassword from "../ResetPassword/ResetPassword";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//Estilos
import "./App.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Sala from "../Sala/Sala";
import Tiradas from "../Tiradas/Tiradas";
import Mesa from "../Mesa/Mesa";
import Tirada1 from "../../components/Tiradas/TIrada1";
import { CartasContextProvider } from "../../context/CartasContext";
import Tirada2 from "../../components/Tiradas/Tirada2";
import Tirada3 from "../../components/Tiradas/Tirada3";
import TiradaDetalle from "../TiradaDetalle/TiradaDetalle";
import Tirada5 from "../../components/Tiradas/Tirada5";
import Tirada4 from "../../components/Tiradas/Tirada4";
import Tirada6 from "../../components/Tiradas/Tirada6";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useFetch from "../../hooks/useFetch";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import Checkout from "../Checkout/Checkout";
import Tirada7 from "../../components/Tiradas/Tirada7";
import Tirada8 from "../../components/Tiradas/Tirada8";
import Tirada9 from "../../components/Tiradas/Tirada9";
import Tirada10 from "../../components/Tiradas/Tirada10";
import Tirada11 from "../../components/Tiradas/Tirada11";
import Tirada12 from "../../components/Tiradas/Tirada12";
import Tirada13 from "../../components/Tiradas/Tirada13";
import Tirada14 from "../../components/Tiradas/Tirada14";
import Tirada15 from "../../components/Tiradas/Tirada15";
import Tirada16 from "../../components/Tiradas/Tirada16";
import Tirada17 from "../../components/Tiradas/Tirada17";

/**
 * Screen App
 * @description: Es la pantalla que cubre a la App y que tiene definidos
 * los routes de navegación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

const stripePromise = loadStripe(
  "pk_test_51NzkuPCrBwAOeTTQyWGhbwU4jhzJ2lWij5nfrU7cPJPltsQA5y8YLhdfIAiOBO4I0pQC8jt7nXm9aBQ3jDTPZGNe00OwEZesQG"
);

const App = () => {
  const authContext = useContext(AuthContext);
  const queryClient = new QueryClient();
  const { sendRequest } = useFetch();
  const [options, setOptions] = useState({ clientSecret: undefined });

  const transformData = useCallback((variable: any) => {
    setOptions({ clientSecret: variable.client_secret });
  }, []);

  useEffect(() => {
    sendRequest(
      {
        url: "/client_secret",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  if (!options.clientSecret) {
    return <p>Cargando</p>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise}>
        <div className="App">
          <Routes>
            {!authContext.isLoggedIn && (
              <>
                <Route path="/" element={<Login />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
            {authContext.isLoggedIn && (
              <>
                <Route path="*" element={<Navigate to="/main" />} />
                <Route path="/main" element={<Main />}>
                  <Route index element={<Home />} />
                  <Route path="payment_method" element={<PaymentMethod />} />
                  <Route path="checkout" element={<Checkout />} />
                  <Route path="sala" element={<Sala />} />

                  <Route
                    path="mesa"
                    element={
                      <CartasContextProvider>
                        <Mesa />
                      </CartasContextProvider>
                    }
                  >
                    <Route path="tiradas" element={<Tiradas />} />
                    <Route path="tirada1" element={<Tirada1 />} />
                    <Route path="tirada2" element={<Tirada2 />} />
                    <Route path="tirada3" element={<Tirada3 />} />
                    <Route path="tirada4" element={<Tirada4 />} />
                    <Route path="tirada5" element={<Tirada5 />} />
                    <Route path="tirada6" element={<Tirada6 />} />
                    <Route path="tirada7" element={<Tirada7 />} />
                    <Route path="tirada8" element={<Tirada8 />} />
                    <Route path="tirada9" element={<Tirada9 />} />
                    <Route path="tirada10" element={<Tirada10 />} />
                    <Route path="tirada11" element={<Tirada11 />} />
                    <Route path="tirada12" element={<Tirada12 />} />
                    <Route path="tirada13" element={<Tirada13 />} />
                    <Route path="tirada14" element={<Tirada14 />} />
                    <Route path="tirada15" element={<Tirada15 />} />
                    <Route path="tirada16" element={<Tirada16 />} />
                    <Route path="tirada17" element={<Tirada17 />} />
                  </Route>
                  <Route
                    path="detalle/:idColeccion"
                    element={<TiradaDetalle />}
                  />
                </Route>
              </>
            )}
          </Routes>
          <ToastContainer limit={1} />
        </div>
      </Elements>
    </QueryClientProvider>
  );
};

export default App;
