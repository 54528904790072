import { useContext } from "react";
import { CartasContext } from "../../context/CartasContext";
import Carta from "../Carta/Carta";
import { Loading } from "../Loading/Loading";
import { Container, Form } from "react-bootstrap";
import { useGlobalDivMeasures } from "../../hooks/useGlobalDivMeasures";

const Tirada6 = () => {
  const cartasContext = useContext(CartasContext);
  const { globalDivMeasures, updateSize } = useGlobalDivMeasures();

  if (!cartasContext.cartasSeleccionadas) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Form.Range onChange={updateSize} defaultValue={50} />
      </Container>
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: globalDivMeasures.width,
          height: globalDivMeasures.height,
        }}
      >
        {cartasContext.cartasSeleccionadas.map((carta, index) => {
          let rightPosition = 1430;
          let topPosition = 280;
          let rotation = -45;
          let partial = 0;
          if (index < 7) {
            partial = globalDivMeasures.height * 0.2 * (3 - index);
            partial = index > 3 ? partial * -1 : partial;
            topPosition = globalDivMeasures.height * 0.0 + partial;
            rightPosition =
              0.725 * globalDivMeasures.width -
              0.1 * globalDivMeasures.width * index;
            rotation = -90 + 30 * index;
          } else if (index > 6 && index < 12) {
            partial = globalDivMeasures.height * 0.2 * (3 - index + 6);
            topPosition =
              index > 9
                ? globalDivMeasures.height * 0.4 - partial
                : globalDivMeasures.height * 0.4 + partial;
            rightPosition =
              1.325 * globalDivMeasures.width -
              0.1 * globalDivMeasures.width * index;
            rotation = -45 + 22.5 * (index - 7);
          } else if (index > 11 && index < 15) {
            partial = globalDivMeasures.height * 0.2 * (3 - index + 10);
            topPosition =
              index > 13
                ? globalDivMeasures.height * 0.8 - partial
                : globalDivMeasures.height * 0.8 + partial;
            rightPosition =
              1.725 * globalDivMeasures.width -
              0.1 * globalDivMeasures.width * index;
            rotation = -30 + 30 * (index - 12);
          } else {
            topPosition = globalDivMeasures.height * 1.2;
            rightPosition = globalDivMeasures.width / 2.4;
            rotation = 0;
          }
          return (
            <Carta
              key={carta.id}
              height={`${globalDivMeasures.widthCard}px`}
              width={`${globalDivMeasures.heightCard}px`}
              srcImage={carta.url}
              rightPosition={`${rightPosition}px`}
              topPosition={`${topPosition}px`}
              orientation={carta.orientation}
              rotation={rotation}
            />
          );
        })}
      </div>
    </>
  );
};

export default Tirada6;
