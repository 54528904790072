import { Accordion, Badge, Button, Container } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Loading } from "../../components/Loading/Loading";
import { ColeccionInterface } from "../../interfaces/ColeccionInterface";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const Sala = () => {
  const { sendRequest } = useFetch();
  const [colecciones, setColecciones] = useState<ColeccionInterface[]>();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setColecciones(variable);
  }, []);

  //Función que llama todos los prueba paginados
  useEffect(() => {
    sendRequest(
      {
        url: !authContext.tokenCan("Tiradas:analizar")
          ? `/coleccion?cliente[eq]=${authContext.usuario?.id}`
          : `/coleccion?tarotista[eq]=${authContext.usuario?.id}`,
      },
      transformData
    );
  }, [sendRequest, transformData, authContext]);

  if (!colecciones) {
    return <Loading />;
  }

  return (
    <Container style={{ paddingTop: 20 }}>
      <h2 style={{ color: "white", fontWeight: "bold" }}>Mis tiradas</h2>
      <Accordion defaultActiveKey="0">
        {colecciones.map((coleccion) => {
          let creation_date = new Date(coleccion.fecha_creacion);
          return (
            <Accordion.Item
              eventKey={coleccion.id.toString()}
              key={coleccion.id}
            >
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={faCircle}
                  color={coleccion.interpretacion ? "blue" : "red"}
                  style={{ marginRight: 5 }}
                />
                <Badge bg="secondary" style={{ marginRight: 10 }}>
                  {creation_date.toLocaleDateString("en-US", {
                    timeZone: "America/Denver",
                  })}
                </Badge>
                <Badge bg="dark" style={{ marginRight: 10 }}>
                  {creation_date.toLocaleTimeString("en-US", {
                    timeZone: "America/Denver",
                  })}
                </Badge>
                {coleccion.tipo_tirada?.nombre}
              </Accordion.Header>
              <Accordion.Body style={{ textAlign: "left" }}>
                <p>
                  <strong>Fecha: </strong>
                  {creation_date.toLocaleDateString("en-US", {
                    timeZone: "America/Denver",
                  })}{" "}
                  {creation_date.toLocaleTimeString("en-US", {
                    timeZone: "America/Denver",
                  })}
                </p>
                <p>
                  <strong>Usuario: </strong>
                  {`${coleccion.cliente.nombre} ${
                    coleccion.cliente.apellido_paterno
                  } ${
                    coleccion.cliente.apellido_materno
                      ? coleccion.cliente.apellido_materno
                      : ""
                  }`}
                </p>
                <Button
                  variant="outline-dark"
                  onClick={() => navigate(`/main/detalle/${coleccion.id}`)}
                >
                  {!authContext.tokenCan("Tiradas:analizar")
                    ? "Ver tirada"
                    : "Atender"}
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Container>
  );
};

export default Sala;
