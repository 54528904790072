import { useEffect, useState } from "react";
import { MeasuresDiv } from "../interfaces/MeasureDiv.type";

export const useGlobalDivMeasures = () => {
  const [globalDivMeasures, setGLobalDivMeasures] = useState<MeasuresDiv>({
    width: 0,
    height: 0,
    widthCard: 0,
    heightCard: 0,
  });

  useEffect(() => {
    setGLobalDivMeasures({
      width: window.innerWidth * 0.6 * 0.9,
      height: window.innerHeight * 0.6 * 0.9,
      widthCard: window.innerWidth * 0.2 * 0.9,
      heightCard: window.innerHeight * 0.2 * 0.9,
    });
  }, []);

  const updateSize = (event: any) => {
    let scale = (event.target.value * 1.8) / 100;
    setGLobalDivMeasures((prev) => {
      return {
        width: window.innerWidth * 0.6 * scale,
        height: window.innerHeight * 0.6 * scale,
        widthCard: window.innerWidth * 0.2 * scale,
        heightCard: window.innerHeight * 0.2 * scale,
      };
    });
  };

  return { globalDivMeasures, updateSize };
};
