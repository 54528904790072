import { useEffect, useState } from "react";
import { CartaInterface } from "../../interfaces/CartaInterface";
import Carta from "../Carta/Carta";
import { Loading } from "../Loading/Loading";
import { Container, Form } from "react-bootstrap";

type Props = {
  cartas: CartaInterface[] | undefined;
};

type MeasuresDiv = {
  width: number;
  height: number;
  widthCard: number;
  heightCard: number;
};

const Tirada6Vis = ({ cartas }: Props) => {
  const [globalDivMeasures, setGLobalDivMeasures] = useState<MeasuresDiv>({
    width: 0,
    height: 0,
    widthCard: 0,
    heightCard: 0,
  });

  useEffect(() => {
    setGLobalDivMeasures({
      width: window.innerWidth * 0.6 * 0.9,
      height: window.innerHeight * 0.6 * 0.9,
      widthCard: window.innerWidth * 0.2 * 0.9,
      heightCard: window.innerHeight * 0.2 * 0.9,
    });
  }, []);

  const updateSize = (event: any): void => {
    let scale = (event.target.value * 1.8) / 100;
    setGLobalDivMeasures((prev) => {
      return {
        width: window.innerWidth * 0.6 * scale,
        height: window.innerHeight * 0.6 * scale,
        widthCard: window.innerWidth * 0.2 * scale,
        heightCard: window.innerHeight * 0.2 * scale,
      };
    });
  };

  if (!cartas) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Form.Range onChange={updateSize} defaultValue={50} />
      </Container>
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: globalDivMeasures.width,
          height: globalDivMeasures.height,
        }}
      >
        {cartas.map((carta, index) => {
          let rightPosition = 1430;
          let topPosition = 280;
          let rotation = -45;
          let partial = 0;
          if (index < 7) {
            partial = globalDivMeasures.height * 0.2 * (3 - index);
            partial = index > 3 ? partial * -1 : partial;
            topPosition = globalDivMeasures.height * 0.0 + partial;
            rightPosition =
              0.725 * globalDivMeasures.width -
              0.1 * globalDivMeasures.width * index;
            rotation = -90 + 30 * index;
          } else if (index > 6 && index < 12) {
            partial = globalDivMeasures.height * 0.2 * (3 - index + 6);
            topPosition =
              index > 9
                ? globalDivMeasures.height * 0.4 - partial
                : globalDivMeasures.height * 0.4 + partial;
            rightPosition =
              1.325 * globalDivMeasures.width -
              0.1 * globalDivMeasures.width * index;
            rotation = -45 + 22.5 * (index - 7);
          } else if (index > 11 && index < 15) {
            partial = globalDivMeasures.height * 0.2 * (3 - index + 10);
            topPosition =
              index > 13
                ? globalDivMeasures.height * 0.8 - partial
                : globalDivMeasures.height * 0.8 + partial;
            rightPosition =
              1.725 * globalDivMeasures.width -
              0.1 * globalDivMeasures.width * index;
            rotation = -30 + 30 * (index - 12);
          } else {
            topPosition = globalDivMeasures.height * 1.2;
            rightPosition = globalDivMeasures.width / 2.4;
            rotation = 0;
          }

          return (
            <Carta
              key={carta.id}
              height={`${globalDivMeasures.widthCard}px`}
              width={`${globalDivMeasures.heightCard}px`}
              srcImage={carta.url}
              rightPosition={`${rightPosition}px`}
              topPosition={`${topPosition}px`}
              orientation={carta.orientation}
              rotation={rotation}
            />
          );
        })}
      </div>
    </>
  );
};

export default Tirada6Vis;
