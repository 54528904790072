import { useContext } from "react";
import { CartasContext } from "../../context/CartasContext";
import Carta from "../Carta/Carta";
import { Loading } from "../Loading/Loading";
import { Container, Form } from "react-bootstrap";
import { useGlobalDivMeasures } from "../../hooks/useGlobalDivMeasures";

const Tirada1 = () => {
  const cartasContext = useContext(CartasContext);
  const { globalDivMeasures, updateSize } = useGlobalDivMeasures();

  if (!cartasContext.cartasSeleccionadas) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Form.Range onChange={updateSize} defaultValue={50} />
      </Container>
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: globalDivMeasures.width,
          height: globalDivMeasures.height,
        }}
      >
        {cartasContext.cartasSeleccionadas.map((carta, index) => (
          <Carta
            key={carta.id}
            height={`${globalDivMeasures.widthCard}px`}
            width={`${globalDivMeasures.heightCard}px`}
            srcImage={carta.url}
            topPosition={`50px`}
            rightPosition={`${
              0.125 * globalDivMeasures.width +
              0.15625 * globalDivMeasures.width * index
            }px`}
            orientation={carta.orientation}
          />
        ))}
      </div>
    </>
  );
};

export default Tirada1;
