import { useContext } from "react";
import { CartasContext } from "../../context/CartasContext";
import Carta from "../Carta/Carta";
import { Loading } from "../Loading/Loading";
import { Container, Form } from "react-bootstrap";
import { useGlobalDivMeasures } from "../../hooks/useGlobalDivMeasures";

const Tirada11 = () => {
  const cartasContext = useContext(CartasContext);
  const { globalDivMeasures, updateSize } = useGlobalDivMeasures();

  if (!cartasContext.cartasSeleccionadas) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Form.Range onChange={updateSize} defaultValue={50} />
      </Container>
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: globalDivMeasures.width,
          height: globalDivMeasures.height,
        }}
      >
        {cartasContext.cartasSeleccionadas.map((carta, index) => {
          let rightPosition = globalDivMeasures.width / 2.4;
          let topPosition = 0;
          if (index === 1) {
            rightPosition = globalDivMeasures.width / 1.5;
            topPosition = globalDivMeasures.height * 0.5;
          } else if (index === 2) {
            rightPosition = globalDivMeasures.width / 1.1;
            topPosition = globalDivMeasures.height * 1.2;
          } else if (index === 3) {
            rightPosition = globalDivMeasures.width / 1.5;
            topPosition = globalDivMeasures.height * 2;
          } else if (index === 4) {
            rightPosition = globalDivMeasures.width / 6.4;
            topPosition = globalDivMeasures.height * 0.5;
          } else if (index === 5) {
            rightPosition = -globalDivMeasures.width * 0.1;
            topPosition = globalDivMeasures.height * 1.2;
          } else if (index === 6) {
            rightPosition = globalDivMeasures.width / 6.4;
            topPosition = globalDivMeasures.height * 2;
          }

          return (
            <Carta
              key={carta.id}
              height={`${globalDivMeasures.widthCard}px`}
              width={`${globalDivMeasures.heightCard}px`}
              srcImage={carta.url}
              rightPosition={`${rightPosition}px`}
              topPosition={`${topPosition}px`}
              orientation={carta.orientation}
            />
          );
        })}
      </div>
    </>
  );
};

export default Tirada11;
