import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PaqueteInterface } from "../../interfaces/PaqueteInterface";
import { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../Loading/Loading";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

//estilos
const classes = require("./PaquetesModal.module.css").default;

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
}

const PaquetesModal = ({ handleCloseModal, showModal }: Props) => {
  const { sendRequest } = useFetch();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [paquetes, setPaquetes] = useState<PaqueteInterface[]>();

  const transformData = useCallback((variable: any) => {
    setPaquetes(variable);
  }, []);

  useEffect(() => {
    sendRequest(
      {
        url: "/paquete",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const buyPackage = (paquete: PaqueteInterface): void => {
    if (window.confirm("¿Seguro que desea adquirir este paquete?")) {
      sendRequest(
        {
          url: "/get_package",
          method: "POST",
          body: {
            paquete_id: paquete.id,
            tiradas_restantes: paquete.tiradas,
          },
        },
        (variable: any) => {
          authContext.updatePaquete(variable);
        }
      );
    }
    handleCloseModal();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} size="xl">
      <Modal.Header
        style={{ backgroundColor: "#352374", color: "white" }}
        closeButton
      >
        <Modal.Title>Adquiere un paquete</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.modalBody}>
        {!paquetes && <Loading />}
        <div className={classes.containercards}>
          {paquetes?.map((paquete: PaqueteInterface) => (
            <div className={classes.card} key={paquete.id}>
              <p className={classes.titlePaquete}>
                <strong>{paquete.nombre}</strong>
              </p>
              <p className={classes.number}>{paquete.tiradas}</p>
              <p>horas</p>
              <p
                className={classes.cardFooter}
              >{`$${paquete.costo_tirada} MXN por tirada`}</p>
              <Button
                variant="outline-light"
                title="Adquirir este paquete"
                onClick={() => buyPackage(paquete)}
              >
                Seleccionar
              </Button>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#352374", color: "white" }}>
        <Button
          variant="outline-light"
          onClick={handleCloseModal}
          title="Cerrar"
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaquetesModal;
