//Hooks
import { toast } from "react-toastify";
import { useContext, useRef, useState } from "react";
import { useMutationData } from "../../hooks/useMutationData";
import { AuthContext } from "../../context/AuthContext";
import { Loading } from "../../components/Loading/Loading";

//estilos
const classes = require("./Login.module.css").default;

/**
 * Screen Login
 * @description: Es la pantalla para iniciar sesión.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */
const Login = () => {
  const mutateLogin = useMutationData("/login", "post");
  const correoInputRef = useRef<HTMLInputElement>(null);
  const contrasenaInputRef = useRef<HTMLInputElement>(null);
  const authContext = useContext(AuthContext);
  const [rightPanel, setRightPanel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  toast.clearWaitingQueue();

  //Función que hace el POST para crear la unidad.
  const login = async (data: any) => {
    setLoading(true);
    try {
      const loginData = await mutateLogin.mutateAsync(data);
      authContext.login(
        loginData["data"].usuario,
        loginData["data"].token,
        loginData["data"].permisos,
        loginData["data"].paquete
      );
    } catch (error) {
      toast.error("Correo o contraseña incorrectos", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      // authContext.login(
      //   {
      //     id: 1,
      //     correo: "admin@admin1.com",
      //     nombre: "Admin",
      //     apellido_paterno: "Admin",
      //     apellido_materno: "",
      //     estatus: 1,
      //     municipio: "",
      //     telefono: "",
      //     rol: {
      //       id: 1,
      //       nombre: "Administrador",
      //       descripcion: "Administrador del sistema",
      //     },
      //   },
      //   "22|XsuQmK3M9ijMO9DObxBIgWxU34yZ8bNdgSzLNQ4X",
      //   ["Usuario:show"]
      // );
    }
    setLoading(false);
  };

  return (
    <div className={classes.maincontainer}>
      <div
        className={`${classes.container} ${
          rightPanel ? classes.rightPanelActive : ""
        }`}
      >
        {!loading && (
          <>
            <div
              className={`${classes.formContainer} ${classes.signUpContainer} ${
                rightPanel ? classes.trasladeHSignUp : ""
              }`}
            >
              {/** Formulario para recuperar contraseña */}
              <form
                className={classes.formulario}
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <h1 className={classes.header}>Recuperar Contraseña</h1>
                <input
                  className={classes.inputLogin}
                  type="email"
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                  required
                />
                <button className={classes.buttonLogin}>Enviar correo</button>
              </form>
            </div>
            <div
              className={`${classes.formContainer} ${classes.signInContainer} ${
                rightPanel ? classes.trasladeHSignIn : ""
              }`}
            >
              {/** Formulario para iniciar sesión */}
              <form
                className={classes.formulario}
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <h1 className={classes.header}>Iniciar sesión</h1>
                <input
                  className={classes.inputLogin}
                  name="correo"
                  type="email"
                  placeholder="Correo"
                  ref={correoInputRef}
                  required
                />
                <input
                  className={classes.inputLogin}
                  type="password"
                  placeholder="Contraseña"
                  name="contrasena"
                  ref={contrasenaInputRef}
                  required
                />
                {/* <a className={classes.link} href="#">
            Forgot your password?
          </a> */}
                <button
                  className={classes.buttonLogin}
                  onClick={() => {
                    login({
                      correo: correoInputRef.current?.value,
                      contrasena: contrasenaInputRef.current?.value,
                    });
                  }}
                >
                  Ingresar
                </button>
              </form>
            </div>
            <div
              className={`${classes.overlayContainer} ${
                rightPanel ? classes.trasladeHOverlay : ""
              }`}
            >
              <div
                className={`${classes.overlay} ${
                  rightPanel ? classes.trasladeHOver : ""
                }`}
              >
                <div
                  className={`${classes.overlayPanel} ${classes.overlayLeft} ${
                    rightPanel ? classes.trasladeHOverLeft : ""
                  }`}
                >
                  <h1 className={classes.header}>Tarot</h1>
                  <p className={classes.pDialog}>
                    Recibirás un correo con el enlace para recuperar tu
                    contraseña
                  </p>
                  <button
                    className={`${classes.buttonLogin} ${classes.ghost}`}
                    id="signIn"
                    onClick={() => setRightPanel(false)}
                  >
                    Iniciar sesión
                  </button>
                </div>
                <div
                  className={`${classes.overlayPanel} ${classes.overlayRight} ${
                    rightPanel ? classes.trasladeHOverRight : ""
                  }`}
                >
                  <h1 className={classes.header}>Tarot</h1>
                  <p className={classes.pDialog}>
                    Ingresa y conecta con personas por video en tiempo real.
                  </p>
                  <button
                    className={`${classes.buttonLogin} ${classes.ghost}`}
                    id="signUp"
                    onClick={() => setRightPanel(true)}
                  >
                    Olvidé mi contraseña
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {loading && <Loading />}
      </div>
    </div>
  );
};

export default Login;
