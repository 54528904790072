import { useEffect, useState } from "react";
import { CartaInterface } from "../../interfaces/CartaInterface";
import Carta from "../Carta/Carta";
import { Loading } from "../Loading/Loading";
import { Container, Form } from "react-bootstrap";

type Props = {
  cartas: CartaInterface[] | undefined;
};

type MeasuresDiv = {
  width: number;
  height: number;
  widthCard: number;
  heightCard: number;
};

const Tirada15Vis = ({ cartas }: Props) => {
  const [globalDivMeasures, setGLobalDivMeasures] = useState<MeasuresDiv>({
    width: 0,
    height: 0,
    widthCard: 0,
    heightCard: 0,
  });

  useEffect(() => {
    setGLobalDivMeasures({
      width: window.innerWidth * 0.6 * 0.9,
      height: window.innerHeight * 0.6 * 0.9,
      widthCard: window.innerWidth * 0.2 * 0.9,
      heightCard: window.innerHeight * 0.2 * 0.9,
    });
  }, []);

  const updateSize = (event: any): void => {
    let scale = (event.target.value * 1.8) / 100;
    setGLobalDivMeasures((prev) => {
      return {
        width: window.innerWidth * 0.6 * scale,
        height: window.innerHeight * 0.6 * scale,
        widthCard: window.innerWidth * 0.2 * scale,
        heightCard: window.innerHeight * 0.2 * scale,
      };
    });
  };

  if (!cartas) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Form.Range onChange={updateSize} defaultValue={50} />
      </Container>
      <div
        style={{
          position: "relative",
          margin: "auto",
          width: globalDivMeasures.width,
          height: globalDivMeasures.height,
        }}
      >
        {cartas.map((carta, index) => {
          let rightPosition = globalDivMeasures.width / 2.4;
          let topPosition = globalDivMeasures.height * 0;
          if (index === 1) {
            rightPosition = globalDivMeasures.width / 20;
            topPosition = globalDivMeasures.height * 0.7;
          } else if (index === 2) {
            rightPosition = globalDivMeasures.width / 1.25;
            topPosition = globalDivMeasures.height * 0.7;
          } else if (index === 3) {
            rightPosition = globalDivMeasures.width / 1.6;
            topPosition = globalDivMeasures.height * 1.7;
          } else if (index === 4) {
            rightPosition = globalDivMeasures.width / 7;
            topPosition = globalDivMeasures.height * 1.7;
          } else if (index === 5) {
            rightPosition = globalDivMeasures.width / 2.4;
            topPosition = globalDivMeasures.height * 0.9;
          }

          return (
            <Carta
              key={carta.id}
              height={`${globalDivMeasures.widthCard}px`}
              width={`${globalDivMeasures.heightCard}px`}
              srcImage={carta.url}
              rightPosition={`${rightPosition}px`}
              topPosition={`${topPosition}px`}
              orientation={carta.orientation}
            />
          );
        })}
      </div>
    </>
  );
};

export default Tirada15Vis;
