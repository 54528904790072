import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  llamante: any;
  handleRefuse: () => void;
  handleAnswer: () => void;
}

const CustomModal = ({
  handleCloseModal,
  showModal,
  llamante,
  handleRefuse,
  handleAnswer,
}: Props) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>LLamada entrante de {llamante}!</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleRefuse}>
          Colgar
        </Button>
        <Button variant="primary" onClick={handleAnswer}>
          Responder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
