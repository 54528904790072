import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState } from "react";
import PaquetesModal from "../../components/PaquetesModal/PaquetesModal";

//estilos
const classes = require("./Tiradas.module.css").default;

const Tiradas = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const paqueteNumeroDisponible = authContext.paquete.length;
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Container style={{ paddingTop: 20 }}>
      <div className={classes.agFormatContainer}>
        <div className={classes.agCoursesBox}>
          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada1")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Tiradas de SI o NO
              </div>

              <img
                alt=""
                src={require(`../../img/tiradas/Si_no.jpg`)}
                width={300}
              />
              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada2")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Respuesta contundente
              </div>

              <img
                alt=""
                src={require(`../../img/tiradas/Respuesta_contundente.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada3")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Tirada cruzada</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Cruzada.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada4")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Arcanos mayores</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Arcanos_mayores.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada5")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Cruz mística</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Cruz_mistica.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada6")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Abanico gitano</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Abanico_gitano.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada7")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Perpectiva de negocio
              </div>
              <img
                alt=""
                src={require(`../../img/tiradas/Perpectiva_de_negocio.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada8")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Tirada anual</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Anual.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada9")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Vidas pasadas</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Vidas_pasadas.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada10")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Mensajes seres queridos
              </div>
              <img
                alt=""
                src={require(`../../img/tiradas/Mensajes_seres_queridos.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada11")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Oráculo del emprendedor
              </div>
              <img
                alt=""
                src={require(`../../img/tiradas/Oraculo_del_emprendedor.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>
          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada12")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Corona para el trabajo
              </div>
              <img
                alt=""
                src={require(`../../img/tiradas/Corona_para_el_trabajo.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>

          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada13")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Desbloquear mi don
              </div>
              <img
                alt=""
                src={require(`../../img/tiradas/Desbloquear_mi_don.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>
          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada14")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Compatibilidad de pareja
              </div>
              <img
                alt=""
                src={require(`../../img/tiradas/Compatibilidad_de_pareja.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>
          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada15")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>
                Que siente por mi
              </div>
              <img
                alt=""
                src={require(`../../img/tiradas/Que_siente_por_mi.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>
          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada16")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Tirada del sexo</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Del_sexo.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>
          <div
            className={classes.agCoursesItem}
            onClick={() =>
              paqueteNumeroDisponible
                ? navigate("/main/mesa/tirada17")
                : setShowModal(true)
            }
          >
            <div className={classes.agCoursesItemLink}>
              <div className={classes.agCoursesItemBg}></div>

              <div className={classes.agCoursesItemTitle}>Cruz celta</div>
              <img
                alt=""
                src={require(`../../img/tiradas/Cruz_celta.jpg`)}
                width={300}
              />

              <div className={classes.agCoursesItemDateBox}>
                <span className={classes.agCoursesItemDate}>Seleccionar</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaquetesModal
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
      />
    </Container>
  );
};

export default Tiradas;
