import { useCallback, useContext, useEffect, useState } from "react";
import { Badge, ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useFetch from "../../hooks/useFetch";
import { UsuarioInterface } from "../../interfaces/UsuarioInterface";
import { Loading } from "../Loading/Loading";
import { toast } from "react-toastify";
import { CartasContext } from "../../context/CartasContext";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
}

const SeleccionarTarotistaModal = ({ handleCloseModal, showModal }: Props) => {
  const { sendRequest } = useFetch();
  const [tarotistas, setTarotistas] = useState<UsuarioInterface[]>();
  const [tarotistaElegido, setTarotistaElegido] = useState<number>();
  const navigate = useNavigate();
  const cartasContext = useContext(CartasContext);
  const authContext = useContext(AuthContext);

  //Función que trata los prueba traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setTarotistas(variable);
  }, []);

  //Función que llama todos los prueba paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/usuario?rol_id[eq]=2",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const saveCollection = useCallback((): void => {
    if (!tarotistaElegido) {
      toast.error(`Se tiene que seleccionar un tarotista`, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    let bodyPost = {};
    let tiradas_restantes_actualizadas =
      authContext.paquete[0].pivot!.tiradas_restantes - 1;
    console.log(authContext.paquete);
    bodyPost = {
      cartasSeleccionadas: cartasContext.cartasSeleccionadas,
      cliente_id: authContext.usuario?.id,
      tarotista_id: tarotistaElegido,
      tipo_tirada_id: cartasContext.tiradaActual.id,
      costo: authContext.paquete[0].costo_tirada,
      paquete_id: authContext.paquete[0].id,
      tiradas_restantes_actualizadas: tiradas_restantes_actualizadas,
    };
    sendRequest(
      {
        url: `/coleccion`,
        method: "POST",
        body: bodyPost,
      },
      (variable: any) => {
        if (tiradas_restantes_actualizadas!!) {
          authContext.updatePaquete([
            {
              ...authContext.paquete[0],
              pivot: {
                ...authContext.paquete[0].pivot!,
                tiradas_restantes: tiradas_restantes_actualizadas,
              },
            },
          ]);
        } else {
          authContext.updatePaquete([]);
        }

        handleCloseModal();
        if (!tiradas_restantes_actualizadas) {
          navigate("/main/mesa/tiradas");
        }
        toast.success("Se enviaron las cartas", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }, [
    sendRequest,
    authContext,
    cartasContext.cartasSeleccionadas,
    cartasContext.tiradaActual,
    tarotistaElegido,
    handleCloseModal,
    navigate,
  ]);

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Tarotista</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!tarotistas && <Loading />}
        {tarotistas && tarotistas.length === 0 && (
          <p>Sin tarotistas registrados.</p>
        )}
        {tarotistas && tarotistas.length > 0 && (
          <ListGroup as="ol" numbered>
            {tarotistas.map((tar) => (
              <ListGroup.Item
                key={tar.correo}
                as="li"
                className="d-flex justify-content-between align-items-start"
                style={{
                  cursor: "pointer",
                  backgroundColor: tar.id === tarotistaElegido ? "#8ACCFC" : "",
                }}
                onClick={() => setTarotistaElegido(tar.id)}
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{`${tar.nombre} ${tar.apellido_paterno} ${tar.apellido_materno}`}</div>
                  {tar.correo}
                </div>
                <Badge bg="primary" pill>
                  10
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={saveCollection}>
          Confirmar
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SeleccionarTarotistaModal;
