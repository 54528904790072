import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";

//const classes = require("./Checkout.module.css").default;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const PaymentMethod = () => {
  const { sendRequest } = useFetch();
  const stripe = useStripe();
  const elements = useElements();
  const [cardElement, setCardElement] = useState<StripeCardElement>();

  const authContext = useContext(AuthContext);
  const [clientSecret, setClientSecret] = useState();
  const [nombreCliente, setNombreCliente] = useState("");

  const transformData = useCallback((variable: any) => {
    setClientSecret(variable.intent.client_secret);
  }, []);

  useEffect(() => {
    sendRequest(
      {
        url: `/client_secret_paymentmethod/${authContext.usuario?.id}`,
      },
      transformData
    );
  }, [sendRequest, transformData, authContext.usuario?.id]);

  useEffect(() => {
    if (elements) {
      setCardElement(elements.create("card"));
    }
  }, [elements]);

  useEffect(() => {
    if (cardElement) {
      cardElement.mount("#card-element");
    }
  }, [cardElement]);

  const checkPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }
    const { setupIntent, error } = await stripe.confirmCardSetup(
      clientSecret!,
      {
        payment_method: {
          card: cardElement!,
          billing_details: {
            name: nombreCliente,
          },
        },
      }
    );

    if (error) {
      console.log(error);
    } else {
      sendRequest(
        {
          url: "/save_payment_method",
          method: "POST",
          body: { payment_method: setupIntent.payment_method },
        },
        (variable: any) => {
          toast.success("Se ha registrado la tarjeta de credito", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
    }
  };

  if (!stripe && !clientSecret) {
    return <p>Loading</p>;
  }

  return (
    <Container>
      <Card style={{ width: "50rem" }}>
        <Card.Body>
          <Card.Title>Ingrese forma de pago</Card.Title>
          <input
            type="text"
            placeholder="Nombre completo"
            value={nombreCliente}
            onChange={(e) => setNombreCliente(e.target.value)}
          />
          <div id="card-element"></div>
          <Button
            variant="primary"
            disabled={!stripe}
            onClick={checkPaymentMethod}
          >
            Confirmar
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PaymentMethod;
