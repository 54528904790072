/**
 * Screen Home
 * @description: Es la pantalla principal de la aplicación.
 * @date 06/07/2023.
 * @returns JSX del Screen.
 */
import { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import adapter from "webrtc-adapter";
import janus from "janus-gateway";
import { toast } from "react-toastify";
import CustomModal from "../../components/CustomModal/CustomModal";

//estilos
const classes = require("./Home.module.css").default;

const Home = () => {
  const server = "wss://videocall.omitech.com.mx:8989/";
  const iceServers = undefined;
  const opaqueId = "videocalltest-" + janus.randomString(12);

  const videocall = useRef<any>();
  const jsepVar = useRef<any>();
  const localTracks = useRef<any>({});
  const localVideos = useRef<number>(0);
  const leftVideo = useRef<any>();

  const remoteTracks = useRef<any>({});
  const remoteVideos = useRef<any>();
  const rightAudio = useRef<any>();
  const rightVideo = useRef<any>();
  const rightVideoContainer = useRef<any>();

  const [enLlamada, setEnLlamada] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [toCallUsername, setToCallUsername] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [yourusername, setYourUserName] = useState(null);

  let myusername: any = null;
  let simulcastStarted: any = null;
  let bitrateTimer: any = null;
  let janux: any = null;

  // Helper to parse query string
  const getQueryStringValue = (name: any) => {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const doSimulcast = useRef<any>(
    getQueryStringValue("simulcast") === "yes" ||
      getQueryStringValue("simulcast") === "true"
  );

  //Funcion para colgar una llamada
  const doHangup = () => {
    setShowModal(false);
    setEnLlamada(false);
    let hangup = { request: "hangup" };
    videocall.current.send({ message: hangup });
    videocall.current.hangup();
    setYourUserName(null);
  };

  const doAnswer = () => {
    setEnLlamada(true);
    videocall.current.createAnswer({
      jsep: jsepVar.current,
      // We want bidirectional audio and video, if offered,
      // plus data channels too if they were negotiated
      tracks: [
        { type: "audio", capture: true, recv: true },
        { type: "video", capture: true, recv: true },
        { type: "data" },
      ],
      success: function (jsep: any) {
        janus.debug("Got SDP!", jsep);
        let body = { request: "accept" };
        videocall.current.send({ message: body, jsep: jsep });
        setShowModal(false);
        //Poner el boton para colgar
      },
      error: function (error: any) {
        janus.error("WebRTC error:", error);
        window.alert("WebRTC error... " + error.message);
      },
    });
  };

  const registerUser = (): void => {
    if (username === "") {
      window.alert("Insert a username to register (e.g., pippo)");
      return;
    }
    if (/[^a-zA-Z0-9]/.test(username)) {
      window.alert("Input is not alphanumeric");
      return;
    }
    let register = { request: "register", username: username };
    videocall.current.send({ message: register });
  };

  const callUser = (): void => {
    if (toCallUsername === "") {
      window.alert("Insert a username to call (e.g., pippo)");
      return;
    }
    if (/[^a-zA-Z0-9]/.test(toCallUsername)) {
      window.alert("Input is not alphanumeric");
      return;
    }
    // Llamar al usuario
    videocall.current.createOffer({
      // We want bidirectional audio and video, plus data channels
      tracks: [
        { type: "audio", capture: true, recv: true },
        { type: "video", capture: true, recv: true, simulcast: doSimulcast },
        { type: "data" },
      ],
      success: function (jsep: any) {
        janus.debug("Got SDP!", jsep);
        let body = { request: "call", username: toCallUsername };
        videocall.current.send({ message: body, jsep: jsep });
      },
      error: function (error: any) {
        janus.error("WebRTC error...", error);
        window.alert("WebRTC error... " + error.message);
      },
    });
  };

  // Helper to escape XML tags
  const escapeXmlTags = (value: any) => {
    if (value) {
      let escapedValue = value.replace(new RegExp("<", "g"), "&lt");
      escapedValue = escapedValue.replace(new RegExp(">", "g"), "&gt");
      return escapedValue;
    }
  };

  const makeLocalTracking = (track: any, on: any) => {
    setEnLlamada(true);
    janus.debug("Local track " + (on ? "added" : "removed") + ":", track);
    let trackId = track.id.replace(/[{}]/g, "");
    if (!on) {
      // Track removed, get rid of the stream and the rendering
      console.log(localTracks.current[trackId]);
      let stream = localTracks.current[trackId];
      if (stream) {
        try {
          let tracks = stream.getTracks();
          for (let i in tracks) {
            let mst = tracks[i];
            if (mst !== null && mst !== undefined) mst.stop();
          }
        } catch (e) {}
      }
      if (track.kind === "video") {
        //Esconder el video.
        localVideos.current -= localVideos.current;
        if (localVideos.current === 0) {
          console.log("No hay video por lo mientras");
          // Mostrar un placeholder mientras no hay video.
          // if ($("#videoleft .no-video-container").length === 0) {
          //  // $("#videoleft").append(
          //  //  '<div class="no-video-container">' +
          //  //    '<i class="fa fa-video-camera fa-5 no-video-icon"></i>' +
          //  //    '<span class="no-video-text">No webcam available</span>' +
          //  //    "</div>"
          //  // );
          // }
        }
      }
      delete localTracks.current[trackId];
      return;
    }
    let stream = localTracks.current[trackId];
    //mostrar el video (booleano a true para mostrar)
    if (track.kind === "audio") {
      // We ignore local audio tracks, they'd generate echo anyway
      if (localVideos.current === 0) {
        // No video, at least for now: show a placeholder
        //Mostrar un placehodler porque no hay video por ahora.
        console.log("No hay video mientras");
      }
    } else {
      // New video track: create a stream out of it
      //AQUI SE ADJUNTA EL VIDEO
      localVideos.current++;
      stream = new MediaStream([track]);
      localTracks.current[trackId] = stream;
      janus.log("Created local stream:", stream);
      janus.attachMediaStream(leftVideo.current, stream);
    }
    if (
      videocall.current.webrtcStuff.pc.iceConnectionState !== "completed" &&
      videocall.current.webrtcStuff.pc.iceConnectionState !== "connected"
    ) {
      console.log("Mientras todavia no esta el stream");
    }
  };

  const onClickStart = () => {
    janus.init({
      debug: true,
      dependencies: janus.useDefaultDependencies({ adapter: adapter }),
      callback: function () {
        if (!janus.isWebrtcSupported()) {
          window.alert("No WebRTC support... ");
          return;
        }
        //Se crea la sesion
        janux = new janus({
          server: server,
          iceServers: iceServers,
          apisecret: "]H@33Vb#Hl]06*h5=LqDQOf{(Ho-#LfJ",
          success: () => {
            janux.attach({
              plugin: "janus.plugin.videocall",
              opaqueId: opaqueId,
              success: function (pluginHandle: any) {
                videocall.current = pluginHandle;
                janus.log(
                  "Plugin attached! (" +
                    pluginHandle!.getPlugin() +
                    ", id=" +
                    pluginHandle!.getId() +
                    ")"
                );
              },
              error: function (error: any) {
                janus.error("  -- Error attaching plugin...", error);
                window.alert("  -- Error attaching plugin... " + error);
              },
              consentDialog: function (on: any) {
                janus.debug(
                  "Consent dialog should be " + (on ? "on" : "off") + " now"
                );
                if (on) {
                  // Darken screen and show hint
                  //Mostrar la flechita del navegador
                } else {
                  // Restore screen
                  //restaurar la pantalla
                }
              },
              iceState: function (state: any) {
                janus.log("ICE state changed to " + state);
              },
              mediaState: function (medium: any, on: any, mid: any) {
                janus.log(
                  "Janus " +
                    (on ? "started" : "stopped") +
                    " receiving our " +
                    medium +
                    " (mid=" +
                    mid +
                    ")"
                );
              },
              webrtcState: function (on: any) {
                janus.log(
                  "Janus says our WebRTC PeerConnection is " +
                    (on ? "up" : "down") +
                    " now"
                );
              },
              slowLink: function (uplink: any, lost: any, mid: any) {
                janus.warn(
                  "Janus reports problems " +
                    (uplink ? "sending" : "receiving") +
                    " packets on mid " +
                    mid +
                    " (" +
                    lost +
                    " lost packets)"
                );
              },
              // MESSAGES HANDLER
              onmessage: function (msg: any, jsep: any) {
                janus.debug(" ::: Got a message :::", msg);
                let result = msg["result"];
                if (result) {
                  if (result["list"]) {
                    let list = result["list"];
                    janus.debug("Got a list of registered peers:", list);
                    for (let mp in list) {
                      janus.debug("  >> [" + list[mp] + "]");
                    }
                  } else if (result["event"]) {
                    let event = result["event"];
                    if (event === "registered") {
                      myusername = escapeXmlTags(result["username"]);
                      janus.log(
                        "Successfully registered as " + myusername + "!"
                      );
                      toast.success(`Se registro el usuario ${myusername}`, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                      });
                      // Habilitar botones para hacer llamada
                    } else if (event === "calling") {
                      janus.log("Waiting for the peer to answer...");
                      // TODO Any ringtone?
                      window.alert("Waiting for the peer to answer...");
                    } else if (event === "incomingcall") {
                      janus.log(
                        "Incoming call from " + result["username"] + "!"
                      );
                      setYourUserName(escapeXmlTags(result["username"]));
                      setShowModal(true);
                      // notificar al usuario que le estan marcando
                      //poner boton de contestar
                      jsepVar.current = jsep;
                      // poner boton de declinar llamada
                    } else if (event === "accepted") {
                      let peer = escapeXmlTags(result["username"]);
                      if (!peer) {
                        janus.log("Call started!");
                      } else {
                        janus.log(peer + " accepted the call!");

                        setYourUserName(peer);
                      }
                      // Iniciar videollamada
                      if (jsep)
                        videocall.current.handleRemoteJsep({
                          jsep: jsep,
                        });
                    } else if (event === "update") {
                      // An 'update' event may be used to provide renegotiation attempts
                      if (jsep) {
                        if (jsep.type === "answer") {
                          videocall.current.handleRemoteJsep({
                            jsep: jsep,
                          });
                        } else {
                          videocall.current.createAnswer({
                            jsep: jsep,
                            // We want bidirectional audio and video, if offered,
                            // plus data channels too if they were negotiated
                            tracks: [
                              { type: "audio", capture: true, recv: true },
                              { type: "video", capture: true, recv: true },
                              { type: "data" },
                            ],
                            success: function (jsep: any) {
                              janus.debug("Got SDP!", jsep);
                              let body = { request: "set" };
                              videocall.current.send({
                                message: body,
                                jsep: jsep,
                              });
                            },
                            error: function (error: any) {
                              janus.error("WebRTC error:", error);
                              window.alert("WebRTC error... " + error.message);
                            },
                          });
                        }
                      }
                    } else if (event === "hangup") {
                      janus.log(
                        "Call hung up by " +
                          result["username"] +
                          " (" +
                          result["reason"] +
                          ")!"
                      );
                      // Reset status
                      setEnLlamada(false);
                      videocall.current.hangup();
                    } else if (event === "simulcast") {
                      // Is simulcast in place?
                      let substream = result["substream"];
                      let temporal = result["temporal"];
                      if (
                        (substream !== null && substream !== undefined) ||
                        (temporal !== null && temporal !== undefined)
                      ) {
                        if (!simulcastStarted) {
                          simulcastStarted = true;
                          // Agregar botones de simulcast
                          //addSimulcastButtons(result["videocodec"] === "vp8");
                        }
                        // Actualizar botones de simulcast
                        // updateSimulcastButtons(substream, temporal);
                      }
                    }
                  }
                } else {
                  // FIXME Error?
                  let error = msg["error"];
                  toast.error(error, {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                  });
                  videocall.current.hangup();
                  if (bitrateTimer) clearInterval(bitrateTimer);
                  bitrateTimer = null;
                }
              },
              onlocaltrack: (track: any, on: any) =>
                makeLocalTracking(track, on),
              onremotetrack: function (
                track: any,
                mid: any,
                on: any,
                metadata: any
              ) {
                janus.debug(
                  "Remote track (mid=" +
                    mid +
                    ") " +
                    (on ? "added" : "removed") +
                    (metadata ? " (" + metadata.reason + ") " : "") +
                    ":",
                  track
                );
                if (!on) {
                  console.log("eNTRA DIREFERENTE DE ON");
                  // Track removed, get rid of the stream and the rendering
                  //$("#peervideo" + mid).remove();
                  if (track.kind === "video") {
                    remoteVideos.current--;
                    if (remoteVideos.current === 0) {
                      // No video, at least for now: show a placeholder
                      //Mostrar un placeholder en el video de la derecha.
                    }
                  }
                  delete remoteTracks.current[mid];
                  return;
                }
                // If we're here, a new track was added
                let addButtons = false;
                // if (
                //  $("#videoright audio").length === 0 &&
                //  $("#videoright video").length === 0
                // ) {
                //  addButtons = true;
                //  $("#videos").removeClass("hide").show();
                // }
                ///MOSTRAR VIDEO DE LA DERECHA
                console.log("ANTES DE PREGUNTAR POR EL AUDIO");
                if (track.kind === "audio") {
                  // New audio track: create a stream out of it, and use a hidden <audio> element
                  let stream = new MediaStream([track]);
                  remoteTracks.current[mid] = stream;
                  janus.log("Created remote audio stream:", stream);
                  //agregar el audio en el video de la derecha
                  // $("#videoright").append(
                  //  '<audio class="hide" id="peervideo' +
                  //    mid +
                  //    '" autoplay playsinline/>'
                  // );

                  janus.attachMediaStream(rightAudio.current, stream);
                  if (remoteVideos.current === 0) {
                    // No video, at least for now: show a placeholder
                    //Si la webcam no esta disponible
                  }
                } else {
                  // New video track: create a stream out of it
                  remoteVideos.current++;
                  //Mostrar el video, quitar el mensaje de que no hay webcam disponible.
                  let stream = new MediaStream([track]);
                  remoteTracks.current[mid] = stream;
                  janus.log("Created remote video stream:", stream);
                  janus.attachMediaStream(rightVideo.current, stream);
                  // Note: we'll need this for additional videos too
                  if (!bitrateTimer) {
                    //Mostrar el curbitrate
                    // $("#curbitrate").removeClass("hide").show();
                    bitrateTimer = setInterval(function () {
                      //if (!$("#peervideo" + mid).get(0)) return;
                      // Display updated bitrate, if supported
                      let bitrate = videocall.current.getBitrate();
                      //~ Janus.debug("Current bitrate is " + videocall.getBitrate());
                      //Agregar el bit rate
                      //$("#curbitrate").text(bitrate);
                      // Check if the resolution changed too
                      // let width = rightVideo.current.videoWidth;
                      // let height =rightVideo.current.videoHeight;
                      // if (width > 0 && height > 0)
                      //  $("#curres")
                      //    .removeClass("hide")
                      //    .text(width + "x" + height)
                      //    .show();
                    }, 1000);
                  }
                }
                if (!addButtons) return;
                //Agregar botones y bitrate visualmente
              },
              ondataopen: function (label: any, protocol: any) {
                janus.log("The DataChannel is available!");
              },
              ondata: function (data: any) {
                janus.debug("We got data from the DataChannel!", data);
              },
              oncleanup: () => {},
            });
          },
          error: function (error) {
            janus.error(error);
            window.alert(error);
          },
          destroyed: function () {
            window.location.reload();
          },
        });
      },
    });
  };

  return (
    <Container>
      <h2>Videollamadas</h2>
      <input
        type="text"
        onChange={(event) => setUsername(event.target.value)}
      />
      <button onClick={onClickStart}>Empezar</button>
      <button onClick={registerUser}>Registrar</button>
      <input
        type="text"
        onChange={(event) => setToCallUsername(event.target.value)}
      />
      <button onClick={callUser}>Llamar</button>
      {enLlamada && (
        <div className={classes.container}>
          <div className={classes.boxTheirs} ref={rightVideoContainer}>
            <audio className="hide" ref={rightAudio} autoPlay playsInline />
            <video
              className="rounded centered"
              ref={rightVideo}
              width="700px"
              autoPlay
              playsInline
            />
          </div>
          <div className={`${classes.boxMine} ${classes.overlay}`}>
            <video
              className="rounded centered"
              id="videolefty"
              ref={leftVideo}
              width="100%"
              autoPlay
              playsInline
              muted
            />
          </div>
        </div>
      )}

      <CustomModal
        handleCloseModal={() => setShowModal(false)}
        showModal={showModal}
        llamante={yourusername}
        handleRefuse={doHangup}
        handleAnswer={doAnswer}
      />
    </Container>
  );
};

export default Home;
